/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import {
  GET_HOT_INDUSTRIES,
  GET_HOT_INDUSTRIES_SUCCESS,
  GET_HOT_INDUSTRIES_FAIL,
  GET_INDUSTRIES,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAIL,
  GET_HOT_INDUSTRIES_BY_ID,
  GET_HOT_INDUSTRIES_BY_ID_SUCCESS,
  GET_HOT_INDUSTRIES_BY_ID_FAIL,
  UPDATE_HOT_INDUSTRIES,
  UPDATE_HOT_INDUSTRIES_SUCCESS,
  UPDATE_HOT_INDUSTRIES_FAIL,
  SORT_HOT_INDUSTRIES,
  SORT_HOT_INDUSTRIES_SUCCESS,
  SORT_HOT_INDUSTRIES_FAIL,
  DELETE_INDUSTRY,
  DELETE_INDUSTRY_SUCCESS,
  DELETE_INDUSTRY_FAIL,
  CREATE_HOT_INDUSTRY,
  CREATE_HOT_INDUSTRY_SUCCESS,
  CREATE_HOT_INDUSTRY_FAIL,
} from '../actions/hotIndustriesAction';
import {
  updateHotCareersIndustries,
  deleteElementFromArrayById,
  addElementToBeginningArray,
} from '../../shared/utils/calculation';

const initialState = {
  hotIndustriesList: [],
  industries: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  hotIndustriesEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOT_INDUSTRIES: {
      return { ...state, type: action.type };
    }
    case GET_HOT_INDUSTRIES_SUCCESS: {
      return { ...state, ...{ type: action.type, hotIndustriesList: action.data } };
    }
    case GET_HOT_INDUSTRIES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_HOT_INDUSTRIES_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_HOT_INDUSTRIES_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, hotIndustriesEdit: action.data } };
    }
    case GET_HOT_INDUSTRIES_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case UPDATE_HOT_INDUSTRIES: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_HOT_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          hotIndustriesEdit: action.data,
          hotIndustriesList: updateHotCareersIndustries(state.hotIndustriesList, action.data),
        },
      };
    }
    case UPDATE_HOT_INDUSTRIES_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case CREATE_HOT_INDUSTRY: {
      return { ...state, type: action.type, message: '' };
    }
    case CREATE_HOT_INDUSTRY_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          hotIndustriesEdit: action.data,
          hotIndustriesList: addElementToBeginningArray(state.hotIndustriesList, action.data),
        },
      };
    }
    case CREATE_HOT_INDUSTRY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_INDUSTRY: {
      return { ...state, type: action.type, message: '' };
    }
    case DELETE_INDUSTRY_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          hotIndustriesList: deleteElementFromArrayById(state.hotIndustriesList, action.data.id),
        },
      };
    }
    case DELETE_INDUSTRY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case SORT_HOT_INDUSTRIES: {
      return { ...state, type: action.type };
    }
    case SORT_HOT_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          hotIndustriesList: action.data,
        },
      };
    }
    case SORT_HOT_INDUSTRIES_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case GET_INDUSTRIES: {
      return { ...state, type: action.type };
    }
    case GET_INDUSTRIES_SUCCESS: {
      return { ...state, ...{ type: action.type, industries: action.data } };
    }
    case GET_INDUSTRIES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
