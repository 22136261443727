export const LOAD_DATA_CHART = 'LOAD_DATA_CHART';
export const LOADING_DATA_CHART = 'LOADING_DATA_CHART';
export const LOAD_DATA_CHART_SUCCESS = 'LOAD_DATA_CHART_SUCCESS';
export const LOAD_DATA_CHART_FAILED = 'LOAD_DATA_CHART_FAILED';

export function loadDataChart(from, to) {
  return {
    type: LOAD_DATA_CHART,
    from,
    to,
  };
}
