export const GET_PROMPTS = 'GET_PROMPTS';
export const GET_PROMPTS_SUCCESS = 'GET_PROMPTS_SUCCESS';
export const GET_PROMPTS_FAIL = 'GET_PROMPTS_FAIL';

export const UPDATE_PROMPT = 'UPDATE_PROMPT';
export const UPDATE_PROMPT_SUCCESS = 'UPDATE_PROMPT_SUCCESS';
export const UPDATE_PROMPT_FAIL = 'UPDATE_PROMPT_FAIL';

export function getPrompts() {
  return {
    type: GET_PROMPTS,
  };
}

export function getPromptsSuccess(data) {
  return {
    type: GET_PROMPTS_SUCCESS,
    data,
  };
}

export function getPromptsFail() {
  return {
    type: GET_PROMPTS_FAIL,
  };
}

export function updatePrompt(id, params) {
  return {
    type: UPDATE_PROMPT,
    id,
    params,
  };
}

export function updatePrompSuccess(data) {
  return {
    type: UPDATE_PROMPT_SUCCESS,
    data,
  };
}

export function updatePromptFail() {
  return {
    type: UPDATE_PROMPT_FAIL,
  };
}