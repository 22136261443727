/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import {
  take,
  call,
  put,
} from 'redux-saga/effects';
import {
  LOAD_DESCRIPTION,
  LOADING_DESCRIPTION,
  LOAD_DESCRIPTION_SUCCESS,
  LOAD_DESCRIPTION_FAILED,
  SAVE_DESCRIPTION,
  SAVING_DESCRIPTION,
  SAVE_DESCRIPTION_SUCCESS,
  SAVE_DESCRIPTION_FAILED,
  DELETE_FIELD_DESCRIPTION,
  DELETE_FIELD_DESCRIPTION_SUCCESS,
  DELETE_FIELD_DESCRIPTION_FAILED,
  UPDATE_ORDER_DESCRIPTION,
  UPDATE_ORDER_DESCRIPTION_FAILED,
  UPDATE_ORDER_DESCRIPTION_SUCCESS,
} from '../actions/descriptionAction';

import {
  getDescription, saveDescription, deleteDescriptionById, updateOrderDescription,
} from '../../shared/api/methods/description';

export function* getDescriptionSaga() {
  while (true) {
    try {
      yield take(LOAD_DESCRIPTION);
      yield put({ type: LOADING_DESCRIPTION });
      const data = yield call(getDescription);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: LOAD_DESCRIPTION_FAILED, error });
      } else {
        yield put({ type: LOAD_DESCRIPTION_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: LOAD_DESCRIPTION_FAILED });
    }
  }
}

export function* saveDescriptionSaga() {
  while (true) {
    try {
      const { dataSave } = yield take(SAVE_DESCRIPTION);
      yield put({ type: SAVING_DESCRIPTION });
      const data = yield call(saveDescription, dataSave);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: SAVE_DESCRIPTION_FAILED, error });
      } else {
        yield put({ type: LOAD_DESCRIPTION });
        yield put({ type: SAVE_DESCRIPTION_SUCCESS });
      }
    } catch (error) {
      yield put({ type: SAVE_DESCRIPTION_FAILED, error });
    }
  }
}
export function* deleteDescriptionSaga() {
  while (true) {
    try {
      const { idRow } = yield take(DELETE_FIELD_DESCRIPTION);
      yield put({ type: DELETE_FIELD_DESCRIPTION });
      const data = yield call(deleteDescriptionById, idRow);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_FIELD_DESCRIPTION_FAILED, error });
      } else {
        yield put({ type: LOAD_DESCRIPTION });
        yield put({ type: DELETE_FIELD_DESCRIPTION_SUCCESS });
      }
    } catch (error) {
      yield put({ type: DELETE_FIELD_DESCRIPTION_FAILED, error });
    }
  }
}

export function* updateOrderDescriptionSaga() {
  while (true) {
    try {
      const { data } = yield take(UPDATE_ORDER_DESCRIPTION);
      yield put({ type: UPDATE_ORDER_DESCRIPTION });
      const response = yield call(updateOrderDescription, data);
      if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        const { error } = response;
        yield put({ type: UPDATE_ORDER_DESCRIPTION_FAILED, error });
      } else {
        yield put({ type: UPDATE_ORDER_DESCRIPTION_SUCCESS });
      }
    } catch (error) {
      yield put({ type: UPDATE_ORDER_DESCRIPTION_FAILED, error });
    }
  }
}
