import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

// import MasterTemplate from '../Admin/MasterTemplate/index';
import Dashboard from '../Admin/Dashboard/index';
import JobTemplate from '../Admin/JobTemplate/index';
import Deparment from '../Admin/Deparment/index';
import JobDescription from '../Admin/JobDescription';
import EmailTemplate from '../Admin/EmailTemplate/index';
import EmailTemplateSystem from '../Admin/EmailTemplateSystem/index';
import Recruiter from '../Admin/Recruiter/index';
import HotCareers from '../Admin/HotCareers';
import EditHotCareers from '../Admin/HotCareers/HotCareersForm/index';
import HotIndustries from '../Admin/HotIndustries';
import EditHotIndustries from '../Admin/HotIndustries/HotIndustriesForm/index';
import Jobseeker from '../Admin/Jobseeker/index';
import News from '../Admin/News/Post/index';
import EditNew from '../Admin/News/Post/PostForm/index';
import Tag from '../Admin/News/Tag/index';
import EditTag from '../Admin/News/Tag/TagForm/index';
import Category from '../Admin/News/Category/index';
import EditCategory from '../Admin/News/Category/CatForm/index';
import Login from '../Admin/Login';

import Faq from '../Admin/Faq/Qa/index';
import EditFaq from '../Admin/Faq/Qa/QaForm/index';
import FaqCategory from '../Admin/Faq/Category/index';
import EditFaqCategory from '../Admin/Faq/Category/CatForm/index';
import Contact from '../Admin/Contact/index';
import ContactDetailt from '../Admin/Contact/ContactDetailt/index';
import GlobalSetting from '../Admin/GlobalSetting';
import Questions from '../Admin/QuestionsRecruit';
import Prompt from '../Admin/Prompt';
// import Login from '../Admin/Login/index';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      {/* <Route path="/admin/master-template" component={MasterTemplate} /> */}
      <Route path="/admin/dashboard" component={Dashboard} />
      <Route path="/admin/job-template" component={JobTemplate} />
      <Route path="/admin/deparment" component={Deparment} />
      <Route path="/admin/job-description" component={JobDescription} />
      <Route path="/admin/email-template" component={EmailTemplate} />
      <Route path="/admin/email-templatesystem" component={EmailTemplateSystem} />
      <Route path="/admin/prompt" component={Prompt} />
      <Route path="/admin/recruiters" component={Recruiter} />
      <Route path="/admin/jobseekers" component={Jobseeker} />
      <Route path="/admin/questions" component={Questions} />
      <Route exact path="/admin/news-recruiter/posts" component={News} />
      <Route path="/admin/news-recruiter/posts/:id" component={EditNew} />
      <Route exact path="/admin/news-recruiter/category" component={Category} />
      <Route path="/admin/news-recruiter/category/:id" component={EditCategory} />
      <Route exact path="/admin/news-jobseeker/posts" component={News} />
      <Route path="/admin/news-jobseeker/posts/:id" component={EditNew} />
      <Route exact path="/admin/news-jobseeker/category" component={Category} />
      <Route path="/admin/news-jobseeker/category/:id" component={EditCategory} />
      <Route exact path="/admin/news-jobseeker/tags" component={Tag} />
      <Route exact path="/admin/news-recruiter/tags" component={Tag} />
      <Route path="/admin/news-jobseeker/tag/:id" component={EditTag} />
      <Route path="/admin/news-recruiter/tag/:id" component={EditTag} />
      <Route exact path="/admin/faq-recruiter/qa" component={Faq} />
      <Route path="/admin/faq-recruiter/qa/edit/:id" component={EditFaq} />
      <Route path="/admin/faq-recruiter/qa/add" component={EditFaq} />
      <Route exact path="/admin/faq-recruiter/category" component={FaqCategory} />
      <Route path="/admin/faq-recruiter/category/edit/:id" component={EditFaqCategory} />
      <Route path="/admin/faq-recruiter/category/add" component={EditFaqCategory} />
      <Route exact path="/admin/faq-jobseeker/qa" component={Faq} />
      <Route path="/admin/faq-jobseeker/qa/edit/:id" component={EditFaq} />
      <Route path="/admin/faq-jobseeker/qa/add" component={EditFaq} />
      <Route exact path="/admin/faq-jobseeker/category" component={FaqCategory} />
      <Route path="/admin/faq-jobseeker/category/edit/:id" component={EditFaqCategory} />
      <Route path="/admin/faq-jobseeker/category/add" component={EditFaqCategory} />
      <Route exact path="/admin/contact" component={Contact} />
      <Route path="/admin/contact/:id" component={ContactDetailt} />
      <Route exact path="/admin/hot-careers" component={HotCareers} />
      <Route path="/admin/hot-careers/:id" component={EditHotCareers} />
      <Route exact path="/admin/hot-industries" component={HotIndustries} />
      <Route path="/admin/hot-industries/:id" component={EditHotIndustries} />
      <Route exact path="/admin/global-setting" component={GlobalSetting} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/admin" component={Login} />
        <Route path="/admin/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
