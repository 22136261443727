export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

export const CREATE_FAQ = 'CREATE_FAQ';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAIL = 'CREATE_FAQ_FAIL';

export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAIL = 'UPDATE_FAQ_FAIL';

export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';

export const GET_FAQ_BY_ID = 'GET_FAQ_BY_ID';
export const GET_FAQ_BY_ID_SUCCESS = 'GET_FAQ_BY_ID_SUCCESS';
export const GET_FAQ_BY_ID_FAIL = 'GET_FAQ_BY_ID_FAIL';

export const RESET_MESSAGE = 'RESET_MESSAGE';

export function getFaq(query, catId, faqType, order, asc, limit, skip) {
  return {
    type: GET_FAQ,
    query,
    catId,
    faqType,
    order,
    asc,
    limit,
    skip,
  };
}

export function getFaqSuccess(data) {
  return {
    type: GET_FAQ_SUCCESS,
    data,
  };
}

export function getFaqFail(error) {
  return {
    type: GET_FAQ_FAIL,
    error,
  };
}

export function getFaqById(id) {
  return {
    type: GET_FAQ_BY_ID,
    id,
  };
}

export function getFaqByIdSuccess(data) {
  return {
    type: GET_FAQ_BY_ID_SUCCESS,
    data,
  };
}

export function getFaqByIdFail(error) {
  return {
    type: GET_FAQ_BY_ID_FAIL,
    error,
  };
}

export function createFaq(params) {
  return {
    type: CREATE_FAQ,
    params,
  };
}

export function createFaqSuccess(data) {
  return {
    type: CREATE_FAQ_SUCCESS,
    data,
  };
}

export function createFaqFail(error) {
  return {
    type: CREATE_FAQ_FAIL,
    error,
  };
}

export function updateFaq(id, params) {
  return {
    type: UPDATE_FAQ,
    id,
    params,
  };
}

export function updateFaqSuccess(data) {
  return {
    type: UPDATE_FAQ_SUCCESS,
    data,
  };
}

export function updateFaqFail(error) {
  return {
    type: UPDATE_FAQ_FAIL,
    error,
  };
}

export function deleteFaq(id) {
  return {
    type: DELETE_FAQ,
    id,
  };
}

export function deleteFaqSuccess(data) {
  return {
    type: DELETE_FAQ_SUCCESS,
    data,
  };
}

export function deleteFaqFail(error) {
  return {
    type: DELETE_FAQ_FAIL,
    error,
  };
}

export function resetMessage() {
  return {
    type: RESET_MESSAGE,
  };
}
