import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    // const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard " icon="home" route="/admin/dashboard" />
          <SidebarLink title="Recruiters" icon="users" route="/admin/recruiters" onClick={this.hideSidebar} />
          <SidebarLink title="Job Seekers" icon="list" route="/admin/jobseekers" onClick={this.hideSidebar} />
          <SidebarLink title="Job Template" icon="list" route="/admin/job-template" onClick={this.hideSidebar} />
          <SidebarLink title="Job Description" icon="briefcase" route="/admin/job-description" onClick={this.hideSidebar} />
          <SidebarLink title="Department" icon="users" route="/admin/deparment" onClick={this.hideSidebar} />
          <SidebarLink title="Questions" icon="question-circle" route="/admin/questions" onClick={this.hideSidebar} />
          {/* <SidebarLink title="Email Template" icon="list" route="/admin/email-template" onClick={this.hideSidebar} /> */}
          <SidebarLink title="Email Template" icon="list" route="/admin/email-templatesystem" onClick={this.hideSidebar} />
          <SidebarLink title="Prompt" icon="list" route="/admin/prompt" onClick={this.hideSidebar} />
          <SidebarLink icon="license" title="Contact" route="/admin/contact" onClick={this.hideSidebar} />
          <SidebarLink icon="list" title="Hot Careers" route="/admin/hot-careers" onClick={this.hideSidebar} />
          <SidebarLink icon="list" title="Hot Industries" route="/admin/hot-industries" onClick={this.hideSidebar} />
          <SidebarCategory icon="list" title="News">
            <SidebarCategory title="Job seeker News">
              <SidebarLink title="Category" icon="home" route="/admin/news-jobseeker/category" onClick={this.hideSidebar} />
              <SidebarLink title="Posts" route="/admin/news-jobseeker/posts" onClick={this.hideSidebar} />
              <SidebarLink title="Tags" route="/admin/news-jobseeker/tags" onClick={this.hideSidebar} />
            </SidebarCategory>
            <SidebarCategory title="Recruiter News">
              <SidebarLink title="Category" route="/admin/news-recruiter/category" onClick={this.hideSidebar} />
              <SidebarLink title="Posts" route="/admin/news-recruiter/posts" onClick={this.hideSidebar} />
              <SidebarLink title="Tags" route="/admin/news-recruiter/tags" onClick={this.hideSidebar} />
            </SidebarCategory>
          </SidebarCategory>
          <SidebarCategory icon="list" title="Faq">
            <SidebarCategory title="Jobseeker Faq">
              <SidebarLink title="Category" route="/admin/faq-jobseeker/category" onClick={this.hideSidebar} />
              <SidebarLink title="Qa" route="/admin/faq-jobseeker/qa" onClick={this.hideSidebar} />
            </SidebarCategory>
            <SidebarCategory title="Recruiter Faq">
              <SidebarLink title="Category" route="/admin/faq-recruiter/category" onClick={this.hideSidebar} />
              <SidebarLink title="Qa" route="/admin/faq-recruiter/qa" onClick={this.hideSidebar} />
            </SidebarCategory>
          </SidebarCategory>
          <SidebarLink icon="cog" title="Global Setting" route="/admin/global-setting" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
