export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAILED = 'LOAD_QUESTIONS_FAILED';

export const SAVE_QUESTIONS = 'SAVE_QUESTIONS';
export const SAVE_QUESTIONS_SUCCESS = 'SAVE_QUESTIONS_SUCCESS';
export const SAVE_QUESTIONS_FAILED = 'SAVE_QUESTIONS_FAILED';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILED = 'GET_QUESTIONS_FAILED';

export function getQuestions() {
  return {
    type: GET_QUESTIONS,
  };
}

export function getQuestionsSuccess(data) {
  return {
    type: GET_QUESTIONS_SUCCESS,
    data,
  };
}

export function getQuestionsFailed(error) {
  return {
    type: GET_QUESTIONS_FAILED,
    error,
  };
}

export function saveQuestion(data) {
  return {
    type: SAVE_QUESTIONS,
    data,
  };
}

export function saveQuestionSuccess(data) {
  return {
    type: SAVE_QUESTIONS_SUCCESS,
    data,
  };
}

export function saveQuestionFailed(error) {
  return {
    type: SAVE_QUESTIONS_FAILED,
    error,
  };
}
