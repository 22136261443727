import { take, call, put } from 'redux-saga/effects';
import {
  LOGIN,
  LOGGINING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from '../actions/authenticationAction';
import authentication from '../../shared/api/methods/authentication';

export default function* authenticationSaga() {
  while (true) {
    try {
      const { dataLogin } = yield take(LOGIN);
      yield put({ type: LOGGINING });
      const data = yield call(authentication, dataLogin);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        yield put({ type: LOGIN_FAILED });
      } else {
        yield put({ type: LOGIN_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: LOGIN_FAILED });
    }
  }
}
