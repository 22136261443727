export const GET_EXPERTISES_BY_FUNCTION_ID = 'GET_EXPERTISES_BY_FUNCTION_ID';
export const GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS = 'GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS';
export const GET_EXPERTISES_BY_FUNCTION_ID_FAIL = 'GET_EXPERTISES_BY_FUNCTION_ID_FAIL';

export const CREATE_EXPERTISE = 'CREATE_EXPERTISE';
export const CREATE_EXPERTISE_SUCCESS = 'CREATE_EXPERTISE_SUCCESS';
export const CREATE_EXPERTISE_FAIL = 'CREATE_EXPERTISE_FAIL';

export const UPDATE_EXPERTISE = 'UPDATE_EXPERTISE';
export const UPDATE_EXPERTISE_SUCCESS = 'UPDATE_EXPERTISE_SUCCESS';
export const UPDATE_EXPERTISE_FAIL = 'UPDATE_EXPERTISE_FAIL';

export const DELETE_EXPERTISE_BY_ID = 'DELETE_EXPERTISE_BY_ID';
export const DELETE_EXPERTISE_BY_ID_SUCCESS = 'DELETE_EXPERTISE_BY_ID_SUCCESS';
export const DELETE_EXPERTISE_BY_ID_FAIL = 'DELETE_EXPERTISE_BY_ID_FAIL';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export function updateExpertise(idExpertise, params) {
  return {
    type: UPDATE_EXPERTISE,
    params,
    idExpertise,
  };
}

export function updateExpertiseSuccess(data) {
  return {
    type: UPDATE_EXPERTISE_SUCCESS,
    data,
  };
}

export function updateExpertiseFail() {
  return {
    type: UPDATE_EXPERTISE_FAIL,
  };
}

export function createExpertise(params) {
  return {
    type: CREATE_EXPERTISE,
    params,
  };
}

export function createExpertiseSuccess(data) {
  return {
    type: CREATE_EXPERTISE_SUCCESS,
    data,
  };
}

export function createExpertiseFail() {
  return {
    type: CREATE_EXPERTISE_FAIL,
  };
}

export function getExpertiseByFunctionId(functionId) {
  return {
    type: GET_EXPERTISES_BY_FUNCTION_ID,
    functionId,
  };
}

export function getExpertiseByFunctionIdSuccess(data) {
  return {
    type: GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS,
    data,
  };
}

export function getExpertiseByFunctionIdFail() {
  return {
    type: GET_EXPERTISES_BY_FUNCTION_ID_FAIL,
  };
}

export function deleteExpertiseById(expertiseId) {
  return {
    type: DELETE_EXPERTISE_BY_ID,
    expertiseId,
  };
}

export function deleteExpertiseByIdSuccess() {
  return {
    type: DELETE_EXPERTISE_BY_ID_SUCCESS,
  };
}

export function deleteExpertiseByIdFail() {
  return {
    type: DELETE_EXPERTISE_BY_ID_FAIL,
  };
}

export function resetMessage() {
  return {
    type: RESET_MESSAGE,
  };
}
