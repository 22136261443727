/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAIL,
  GET_CONTACT_BY_ID,
  GET_CONTACT_BY_ID_SUCCESS,
  GET_CONTACT_BY_ID_FAIL,
} from '../actions/contactAction';
import {
  getContact,
  getContactById,
} from '../../shared/api/methods/contact';

export function* getContactSaga() {
  while (true) {
    try {
      yield take(GET_CONTACT);
      const data = yield call(getContact);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_CONTACT_FAIL, error });
      } else {
        yield put({ type: GET_CONTACT_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_CONTACT_FAIL, error });
    }
  }
}

export function* getContactByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_CONTACT_BY_ID);
      const data = yield call(getContactById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_CONTACT_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_CONTACT_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_CONTACT_BY_ID_FAIL, error });
    }
  }
}
