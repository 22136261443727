/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  LOADING_DESCRIPTION,
  LOAD_DESCRIPTION_SUCCESS,
  LOAD_DESCRIPTION_FAILED,
  ADD_FIELD_DESCRIPTION,
  UPDATE_FIELD_DESCRIPTION,
  SAVING_DESCRIPTION,
  SAVE_DESCRIPTION_SUCCESS,
  SAVE_DESCRIPTION_FAILED,
} from '../actions/descriptionAction';


const initalDescription = [];

const initialState = {
  isLoading: false,
  isSaving: false,
  isError: false,
  isShowButtonSave: false,
  message: '',
  statusCode: '',
  data: initalDescription,
};

function returnStateAdd(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup,
  } = action.modal;

  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof indexInGroup !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = [
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields,
        action.data,
      ];
    } else if (typeof indexInTypeSection !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields = [
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields,
        action.data,
      ];
    } else {
      state.data.jobRequirementSection[typeSection].fields = [
        ...state.data.jobRequirementSection[typeSection].fields,
        action.data,
      ];
    }
  } else if (typeof indexInGroup !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = [
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields,
      action.data,
    ];
  } else if (typeof indexInTypeSection !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields = [
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields,
      action.data,
    ];
  } else {
    state.data[typeSection].fields = [
      ...state.data[typeSection].fields,
      action.data,
    ];
  }

  return {
    ...state,
  };
}

function returnStateUpdate(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup, index,
  } = action.modal;
  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof index !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index] = {
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index],
        ...action.data,
      };
    } else if (typeof indexInGroup !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup] = {
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup],
        ...action.data,
      };
    } else if (typeof indexInTypeSection !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection] = {
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection],
        ...action.data,
      };
    }
  } else if (typeof index !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index] = {
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index],
      ...action.data,
    };
  } else if (typeof indexInGroup !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup] = {
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup],
      ...action.data,
    };
  } else if (typeof indexInTypeSection !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection] = {
      ...state.data[typeSection].fields[indexInTypeSection],
      ...action.data,
    };
  }
  return {
    ...state,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DESCRIPTION: {
      return { ...state, isLoading: true };
    }
    case LOAD_DESCRIPTION_FAILED: {
      return {
        ...state, data: initialState, isError: true, statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case LOAD_DESCRIPTION_SUCCESS: {
      return { ...state, data: action.data, isLoading: false };
    }
    case SAVING_DESCRIPTION: {
      return { ...state, isSaving: true };
    }
    case SAVE_DESCRIPTION_SUCCESS: {
      return { ...state, isSaving: false };
    }
    case SAVE_DESCRIPTION_FAILED: {
      return {
        ...state, isSaving: false, isError: true, statusCode: action.data ? action.data.statusCode.toString() : '',
      };
    }
    case ADD_FIELD_DESCRIPTION: {
      return returnStateAdd(state, action);
    }
    case UPDATE_FIELD_DESCRIPTION: {
      return returnStateUpdate(state, action);
    }
    default:
      return state;
  }
}
