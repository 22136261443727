/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_EXPERTISES_BY_FUNCTION_ID,
  GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS,
  GET_EXPERTISES_BY_FUNCTION_ID_FAIL,
  CREATE_EXPERTISE,
  CREATE_EXPERTISE_SUCCESS,
  CREATE_EXPERTISE_FAIL,
  UPDATE_EXPERTISE,
  UPDATE_EXPERTISE_SUCCESS,
  UPDATE_EXPERTISE_FAIL,
  DELETE_EXPERTISE_BY_ID,
  DELETE_EXPERTISE_BY_ID_SUCCESS,
  DELETE_EXPERTISE_BY_ID_FAIL,
  RESET_MESSAGE,
} from '../actions/expertiseAction';
import {
  addElementToBeginningArray,
  updateElementFromArrayById,
  deleteElementFromArrayById,
} from '../../shared/utils/calculation';

import {
  MSG_CREATE_EXPERTISE_SUCCESS,
  MSG_CREATE_EXPERTISE_FAIL,
  MSG_UPDATE_EXPERTISE_SUCCESS,
  MSG_UPDATE_EXPERTISE_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  type: null,
  expertises: [],
  loading: false,
  isError: false,
  statusCode: '',
  message: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    // create expertise
    case CREATE_EXPERTISE: {
      return { ...state, type: action.type };
    }
    case CREATE_EXPERTISE_SUCCESS: {
      return {
        ...state,
        type: action.type,
        expertises: addElementToBeginningArray(state.expertises, action.data),
        message: MSG_CREATE_EXPERTISE_SUCCESS,
      };
    }
    case CREATE_EXPERTISE_FAIL: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
        message: MSG_CREATE_EXPERTISE_FAIL,
      };
    }
    // update expertise
    case UPDATE_EXPERTISE: {
      return { ...state, type: action.type };
    }
    case UPDATE_EXPERTISE_SUCCESS: {
      return {
        ...state,
        type: action.type,
        expertises: updateElementFromArrayById(state.expertises, action.data),
        message: MSG_UPDATE_EXPERTISE_SUCCESS,
      };
    }
    case UPDATE_EXPERTISE_FAIL: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
        message: MSG_UPDATE_EXPERTISE_FAIL,
      };
    }

    // get expertise by function id
    case GET_EXPERTISES_BY_FUNCTION_ID: {
      return { ...state, type: action.type };
    }
    case GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS: {
      return {
        ...state,
        type: action.type,
        expertises: action.data,
      };
    }
    case GET_EXPERTISES_BY_FUNCTION_ID_FAIL: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }

    // delete expertise by id
    case DELETE_EXPERTISE_BY_ID: {
      return { ...state, type: action.type };
    }
    case DELETE_EXPERTISE_BY_ID_SUCCESS: {
      const { id } = action.data;
      return {
        ...state,
        type: action.type,
        expertises: deleteElementFromArrayById(state.expertises, id),
      };
    }
    case DELETE_EXPERTISE_BY_ID_FAIL: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case RESET_MESSAGE: {
      return {
        ...state,
        message: '',
      };
    }
    default:
      return state;
  }
}
