/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_HOT_CAREERS,
  GET_HOT_CAREERS_SUCCESS,
  GET_HOT_CAREERS_FAIL,
  GET_HOT_CAREERS_BY_ID,
  GET_HOT_CAREERS_BY_ID_SUCCESS,
  GET_HOT_CAREERS_BY_ID_FAIL,
  UPDATE_HOT_CAREERS,
  UPDATE_HOT_CAREERS_SUCCESS,
  UPDATE_HOT_CAREERS_FAIL,
  SORT_HOT_CAREERS,
  SORT_HOT_CAREERS_SUCCESS,
  SORT_HOT_CAREERS_FAIL,
} from '../actions/hotCareersAction';
import {
  getHotCareers,
  updateHotCareers,
  getHotCareersById,
} from '../../shared/api/methods/hotCareers';

export function* getHotCareersSaga() {
  while (true) {
    try {
      yield take(GET_HOT_CAREERS);
      const data = yield call(getHotCareers);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_HOT_CAREERS_FAIL, error });
      } else {
        yield put({ type: GET_HOT_CAREERS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_HOT_CAREERS_FAIL, error });
    }
  }
}


export function* updateHotCareersSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_HOT_CAREERS);
      const data = yield call(updateHotCareers, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_HOT_CAREERS_FAIL, error });
      } else {
        yield put({ type: UPDATE_HOT_CAREERS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_HOT_CAREERS_FAIL, error });
    }
  }
}

export function* getHotCareersByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_HOT_CAREERS_BY_ID);
      const data = yield call(getHotCareersById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_HOT_CAREERS_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_HOT_CAREERS_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_HOT_CAREERS_BY_ID_FAIL, error });
    }
  }
}

export function* sortHotCareersSaga() {
  while (true) {
    try {
      const { items } = yield take(SORT_HOT_CAREERS);
      const data = yield Promise.all(items.map(async (item, i) => {
        const result = updateHotCareers([item.id, { sortNum: i }]);
        return result;
      }));
      if (data === null || Object.prototype.hasOwnProperty.call(data[0], 'error')) {
        const { error } = data[0];
        yield put({ type: SORT_HOT_CAREERS_FAIL, error });
      } else {
        yield put({ type: SORT_HOT_CAREERS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: SORT_HOT_CAREERS_FAIL, error });
    }
  }
}
