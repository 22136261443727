import { take, call, put } from 'redux-saga/effects';
import {
  LOAD_DATA_CHART,
  LOADING_DATA_CHART,
  LOAD_DATA_CHART_FAILED,
  LOAD_DATA_CHART_SUCCESS,
} from '../actions/dashboardAction';
import loadData from '../../shared/api/methods/dashboard';

export default function* loadDataChart() {
  while (true) {
    try {
      const { from, to } = yield take(LOAD_DATA_CHART);
      yield put({ type: LOADING_DATA_CHART });
      const data = yield call(loadData, from, to);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: LOAD_DATA_CHART_FAILED, error });
      } else {
        yield put({ type: LOAD_DATA_CHART_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: LOAD_DATA_CHART_FAILED });
    }
  }
}
