/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  LOADING_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAILED,
  ADD_FIELD_TEMPLATE,
  UPDATE_FIELD_TEMPLATE,
  DELETE_FIELD_TEMPLATE,
  SAVING_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILED,
  CLONE_FIELD_TEMPLATE,
} from '../actions/templateAction';


const initalTemplate = {
  id: '',
  informationSection: {
    label: {
      en: 'GENERAL INFO',
      vi: 'THÔNG TIN CHUNG',
    },
    fields: [],
  },
  offerabilitySection: {
    label: {
      en: 'EXPECTATION',
      vi: 'MONG MUỐN',
    },
    fields: [],
  },
  jobRequirementSection: {
    label: {
      en: 'YOUR ABILITIES',
      vi: 'NĂNG LỰC CỦA BẠN',
    },
    hardSkillSection: {
      label: {
        en: 'HARD SKILLS',
        vi: 'KĨ NĂNG CỨNG',
      },
      fields: [],
    },
    softSkillSection: {
      label: {
        en: 'SOFT SKILLS',
        vi: 'KĨ NĂNG MỀM',
      },
      fields: [],
    },
  },
  situationQuestionSection: {
    label: {
      en: 'SITUATION QUESTIONS',
      vi: 'Câu hỏi tình huống',
    },
    fields: [],
  }
};

const initialState = {
  isLoading: false,
  isSaving: false,
  isError: false,
  message: '',
  statusCode: '',
  data: initalTemplate,
};

function returnStateAdd(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup,
  } = action.modal;

  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof indexInGroup !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = [
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields,
        action.data,
      ];
    } else if (typeof indexInTypeSection !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields = [
        ...state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields,
        action.data,
      ];
    } else {
      state.data.jobRequirementSection[typeSection].fields = [
        ...state.data.jobRequirementSection[typeSection].fields,
        action.data,
      ];
    }
  } else if (typeof indexInGroup !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = [
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields,
      action.data,
    ];
  } else if (typeof indexInTypeSection !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields = [
      ...state.data[typeSection].fields[indexInTypeSection].detail.fields,
      action.data,
    ];
  } else {
    if(state.data[typeSection]) {
      state.data[typeSection].fields = [
        ...state.data[typeSection].fields,
        action.data,
      ];
    } else {
      state.data[typeSection] = {
        fields: action.data.fields
      }
    }

  }

  return {
    ...state,
  };
}

function returnStateUpdate(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup, index,
  } = action.modal;
  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof index !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index] = {
        ...action.data,
      };
    } else if (typeof indexInGroup !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup] = {
        ...action.data,
      };
    } else if (typeof indexInTypeSection !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection] = {
        ...action.data,
      };
    }
  } else if (typeof index !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields[index] = {
      ...action.data,
    };
  } else if (typeof indexInGroup !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup] = {
      ...action.data,
    };
  } else if (typeof indexInTypeSection !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection] = {
      ...action.data,
    };
  }
  return {
    ...state,
  };
}

function returnStateDelete(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup, index,
  } = action.modal;
  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof index !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.filter((item, indexItem) => indexItem !== index);
    } else if (typeof indexInGroup !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields = state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields.filter((item, indexItem) => indexItem !== indexInGroup);
    } else if (typeof indexInTypeSection !== 'undefined') {
      state.data.jobRequirementSection[typeSection].fields = state.data.jobRequirementSection[typeSection].fields.filter((item, indexItem) => indexItem !== indexInTypeSection);
    }
  } else if (typeof index !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields = state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.filter((item, indexItem) => indexItem !== index);
  } else if (typeof indexInGroup !== 'undefined') {
    state.data[typeSection].fields[indexInTypeSection].detail.fields = state.data[typeSection].fields[indexInTypeSection].detail.fields.filter((item, indexItem) => indexItem !== indexInGroup);
  } else if (typeof indexInTypeSection !== 'undefined') {
    state.data[typeSection].fields = state.data[typeSection].fields.filter((item, indexItem) => indexItem !== indexInTypeSection);
  }

  return {
    ...state,
  };
}

function repairDataClone(data) {
  const dataCloned = JSON.parse(JSON.stringify(data));
  const labelEnglish = data.label.en;
  const labelVietnamese = data.label.vi;
  dataCloned.label.en = `${labelEnglish} + Copy`;
  dataCloned.label.vi = `${labelVietnamese} + Copy`;
  if (Object.prototype.hasOwnProperty.call(dataCloned, 'fieldName')) delete dataCloned.fieldName;

  return dataCloned;
}

function returnSateClone(state, action) {
  const {
    typeSection, indexInTypeSection, indexInGroup, index,
  } = action.modal;
  if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
    if (typeof index !== 'undefined') {
      const dataFilter = state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.filter((item, indexItem) => indexItem === index);
      const dataClone = repairDataClone(dataFilter[0]);
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.splice(index + 1, 0, dataClone);
    } else if (typeof indexInGroup !== 'undefined') {
      const dataFilter = state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields.filter((item, indexItem) => indexItem === indexInGroup);
      const dataClone = repairDataClone(dataFilter[0]);
      state.data.jobRequirementSection[typeSection].fields[indexInTypeSection].detail.fields.splice(indexInGroup + 1, 0, dataClone);
    } else if (typeof indexInTypeSection !== 'undefined') {
      const dataFilter = state.data.jobRequirementSection[typeSection].fields.filter((item, indexItem) => indexItem === indexInTypeSection);
      const dataClone = repairDataClone(dataFilter[0]);
      state.data.jobRequirementSection[typeSection].fields.splice(indexInTypeSection + 1, 0, dataClone);
    }
  } else if (typeof index !== 'undefined') {
    const dataFilter = state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.filter((item, indexItem) => indexItem === index);
    const dataClone = repairDataClone(dataFilter[0]);
    state.data[typeSection].fields[indexInTypeSection].detail.fields[indexInGroup].detail.fields.splice(index + 1, 0, dataClone);
  } else if (typeof indexInGroup !== 'undefined') {
    const dataFilter = state.data[typeSection].fields[indexInTypeSection].detail.fields.filter((item, indexItem) => indexItem === indexInGroup);
    const dataClone = repairDataClone(dataFilter[0]);
    state.data[typeSection].fields[indexInTypeSection].detail.fields.splice(indexInGroup + 1, 0, dataClone);
  } else if (typeof indexInTypeSection !== 'undefined') {
    const dataFilter = state.data[typeSection].fields.filter((item, indexItem) => indexItem === indexInTypeSection);
    const dataClone = repairDataClone(dataFilter[0]);
    state.data[typeSection].fields.splice(indexInTypeSection + 1, 0, dataClone);
  }

  return {
    ...state,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TEMPLATE: {
      return { ...state, isLoading: true };
    }
    case LOAD_TEMPLATE_FAILED: {
      return {
        ...state, data: initalTemplate, isError: true, statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case LOAD_TEMPLATE_SUCCESS: {
      return { ...state, data: action.data ? action.data : state, isLoading: false };
    }
    case SAVING_TEMPLATE: {
      return { ...state, isSaving: true };
    }
    case SAVE_TEMPLATE_SUCCESS: {
      return { ...state, isSaving: false };
    }
    case SAVE_TEMPLATE_FAILED: {
      return {
        ...state, isSaving: false, isError: true, statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case ADD_FIELD_TEMPLATE: {
      return returnStateAdd(state, action);
    }
    case UPDATE_FIELD_TEMPLATE: {
      return returnStateUpdate(state, action);
    }
    case DELETE_FIELD_TEMPLATE: {
      return returnStateDelete(state, action);
    }
    case CLONE_FIELD_TEMPLATE: {
      return returnSateClone(state, action);
    }
    default:
      return state;
  }
}
