/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAIL,
  CREATE_FUNCTION,
  GET_FUNCTIONS,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  HIDE_STATUS_REQUEST,
} from '../actions/departmentAction';
import {
  addElementToBeginningArray,
  updateElementFromArrayById,
  deleteElementFromArrayById,
} from '../../shared/utils/calculation';

const initialState = {
  departments: [],
  functions: [],
  error: null,
  isFinishRequest: true,
  data: null,
  statusCode: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENTS: {
      return { ...state, type: action.type };
    }
    case GET_DEPARTMENTS_SUCCESS: {
      return { ...state, ...{ type: action.type, departments: action.data } };
    }
    case GET_DEPARTMENTS_FAIL: {
      return { ...state, type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' };
    }
    case CREATE_DEPARTMENT: {
      return { ...state, type: action.type };
    }
    case CREATE_DEPARTMENT_SUCCESS: {
      return { ...state, type: action.type, departments: addElementToBeginningArray(state.departments, action.data) };
    }
    case CREATE_DEPARTMENT_FAIL: {
      return { ...state, type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' };
    }
    case UPDATE_DEPARTMENT: {
      return { ...state, type: action.type };
    }
    case UPDATE_DEPARTMENT_SUCCESS: {
      const { isDeleted, id } = action.data;
      return {
        ...state,
        type: action.type,
        departments: isDeleted
          ? deleteElementFromArrayById(state.departments, id)
          : updateElementFromArrayById(state.departments, action.data),
      };
    }
    case UPDATE_DEPARTMENT_FAIL: {
      return { ...state, type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' };
    }
    case GET_FUNCTIONS: {
      // eslint-disable-next-line no-console
      return { ...state, functions: action.data ? action.data : [] };
    }
    case CREATE_FUNCTION: {
      return { ...state, function: action.data ? action.data : [] };
    }
    case HIDE_STATUS_REQUEST: {
      return {
        ...state,
        data: null,
        error: null,
        isFinishRequest: true,
      };
    }
    default:
      return state;
  }
}
