// General api to access data
import ApiConstants from './ApiConstants';

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const api = async (path, params, method) => {
  const token = getCookie('token');
  const bearer = `Bearer ${token}`;
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: bearer,
    },
    method,
    ...(params && { body: JSON.stringify(params) }),
  };

  try {
    // eslint-disable-next-line no-console
    console.log(path);
    // eslint-disable-next-line no-console
    console.log(method);
    // eslint-disable-next-line no-console
    console.log(params);
    // eslint-disable-next-line no-console
    console.log(options);
    const resp = await fetch(ApiConstants.BASE_URL + path, options);
    const respCopy = resp.clone();
    const json = await respCopy.json();
    return json;
  } catch (error) {
    return error;
  }
};

export default api;
