export const LOAD_TEMPLATE = 'LOAD_TEMPLATE';
export const LOADING_TEMPLATE = 'LOADING_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_FAILED = 'LOAD_TEMPLATE_FAILED';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVING_TEMPLATE = 'SAVING_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILED = 'SAVE_TEMPLATE_FAILED';

export const ADD_FIELD_TEMPLATE = 'ADD_FIELD_TEMPLATE';
export const UPDATE_FIELD_TEMPLATE = 'UPDATE_FIELD_TEMPLATE';
export const DELETE_FIELD_TEMPLATE = 'DELETE_FIELD_TEMPLATE';
export const CLONE_FIELD_TEMPLATE = 'ClONE_FIELD_TEMPLATE';

export function loadTemplate() {
  return {
    type: LOAD_TEMPLATE,
  };
}

export function saveTemplate(dataSave) {
  return {
    type: SAVE_TEMPLATE,
    dataSave,
  };
}

export function addNewField(data, modal) {
  return {
    type: ADD_FIELD_TEMPLATE,
    data,
    modal,
  };
}

export function updateField(data, modal) {
  return {
    type: UPDATE_FIELD_TEMPLATE,
    data,
    modal,
  };
}

export function deleteField(modal) {
  return {
    type: DELETE_FIELD_TEMPLATE,
    modal,
  };
}

export function cloneField(modal) {
  return {
    type: CLONE_FIELD_TEMPLATE,
    modal,
  };
}
