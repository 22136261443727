import api from '../index';
import ApiConstants from '../ApiConstants';

export const getHotCareers = () => api(
  `${ApiConstants.HOT_CAREERS}?order=sortNum&asc=1&limit=100&skip0`,
  null,
  'get',
  null,
);
export const updateHotCareers = args => api(
  `${ApiConstants.DEPARTMENT}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);
export const getHotCareersById = id => api(
  `${ApiConstants.HOT_CAREERS_GET_BY_ID}/${id}`,
  null,
  'GET',
  null,
);
