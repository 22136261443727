/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAILED,
  GET_QUESTIONS_SUCCESS,
  SAVE_QUESTIONS_FAILED,
  SAVE_QUESTIONS_SUCCESS,
} from '../actions/questionsAction';

const initialState = {
  questions: [],
  message: '',
  isError: false,
  statusCode: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTIONS: {
      return { ...state, type: action.type };
    }
    case GET_QUESTIONS_SUCCESS: {
      return { ...state, ...{ type: action.type, data: action.data } };
    }
    case GET_QUESTIONS_FAILED: {
      return { ...state, ...{ type: action.type, data: action.data } };
    }
    case SAVE_QUESTIONS_SUCCESS: {
      const { data } = state;
      const dataUpdate = [...data];
      const { id } = action.data;
      const findIndex = data.findIndex(item => item.id === id);
      if (findIndex >= 0) {
        dataUpdate[findIndex] = action.data;
      } else {
        dataUpdate.push(action.data);
      }
      return { ...state, ...{ data: dataUpdate } };
    }
    case SAVE_QUESTIONS_FAILED: {
      return { ...state, ...{ type: action.type, data: action.data } };
    }
    default:
      return state;
  }
}
