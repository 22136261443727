export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';

export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAIL = 'CREATE_DEPARTMENT_FAIL';

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAIL = 'UPDATE_DEPARTMENT_FAIL';

export const GET_FUNCTIONS = 'GET_FUNCTIONS';
export const CREATE_FUNCTION = 'CREATE_FUNCTION';
export const HIDE_STATUS_REQUEST = 'HIDE_STATUS_REQUEST';

export function getDepartments() {
  return {
    type: GET_DEPARTMENTS,
  };
}

export function getDepartmentsSuccess(data) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    data,
  };
}

export function getDepartmentsFail() {
  return {
    type: GET_DEPARTMENTS_FAIL,
  };
}

export function createDepartment(params) {
  return {
    type: CREATE_DEPARTMENT,
    params,
  };
}
export function createDepartmentSuccess() {
  return {
    type: CREATE_DEPARTMENT_SUCCESS,
  };
}
export function createDepartmentFail() {
  return {
    type: CREATE_DEPARTMENT_FAIL,
  };
}

export function updateDepartment(id, params) {
  return {
    type: UPDATE_DEPARTMENT,
    id,
    params,
  };
}

export function updateDepartmentSuccess(data) {
  return {
    type: UPDATE_DEPARTMENT_SUCCESS,
    data,
  };
}

export function updateDepartmentFail() {
  return {
    type: UPDATE_DEPARTMENT_FAIL,
  };
}

export function getFunctionsByDepartmentId(departmentId) {
  return {
    type: GET_FUNCTIONS,
    departmentId,
  };
}

export function createFunction(data) {
  return {
    type: CREATE_FUNCTION,
    data,
  };
}

export function hideStatusRequest() {
  return {
    type: HIDE_STATUS_REQUEST,
  };
}
