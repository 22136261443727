/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
} from '../actions/uploadFileAction';

const initialState = {
  message: '',
  isError: false,
  data: null,
  statusCode: '',
  url: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE: {
      return { ...state, type: action.type };
    }
    case UPLOAD_FILE_SUCCESS: {
      return { ...state, ...{ type: action.type, url: action.data } };
    }
    case UPLOAD_FILE_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
