/* eslint-disable object-curly-newline */
import { fork, all } from 'redux-saga/effects';
import {
  getDepartmentsSaga,
  getFunctionByDepartmentIdSaga,
  updateDepartmentSaga,
  createDepartmentSaga,
} from './departmentSaga';
import {
  getEmailTemplatesSaga,
  updateEmailTemplateSaga,
  createEmailTemplateSaga,
  getEmailTemplatesSystemSaga,
  updateEmailTemplateSystemSaga,
  createEmailTemplateSystemSaga,
  deleteEmailTemplateSystemSaga,
} from './emailTemplateSaga';
import {
  getFunctionsSaga,
  createFunctionSaga,
  updateFunctionSaga,
  deleteFunctionSaga,
  getFunctionsByIdDeparmentSaga,
} from './functionSaga';

import {
  getExpertisesByFunctionIdSaga,
  deleteExpertiseByIdSaga,
  createExpertiseSaga,
  updateExpertiseSaga,
} from './expertiseSaga';

import { getTemplateSaga, saveTemplateSaga } from './templateSaga';
import {
  getDescriptionSaga, saveDescriptionSaga, deleteDescriptionSaga, updateOrderDescriptionSaga,
} from './descriptionSaga';
import { getRecruitersSaga, createRecruiterSaga, updateRecruiterSaga, deleteRecruiterSaga, getJobsByRecruiterIdSaga } from './recruiterSaga';
import { getJobseekersSaga, updateJobseekerSaga, getJobsByJobseekerIdSaga } from './jobseekerSaga';
import {
  getNewsSaga,
  createNewsSaga,
  updateNewsSaga,
  deleteNewsSaga,
  getNewsByIdSaga,
  getNewsCategoriesSaga,
} from './newsSaga';
import { getTagsSaga, updateTagSaga, deleteTagSaga, createTagSaga, getTagByIdSaga } from './tagSaga';
import {
  getCategoriesSaga,
  updateCategorySaga,
  createCategorySaga,
  getCategoryByIdSaga,
  deleteCategorySaga,
  sortCategorySaga,
} from './categorySaga';
import uploadFileSaga from './uploadFileSaga';
import authenticationSaga from './authenticationSaga';

import {
  createFaqCategorySaga,
  getFaqCategoriesSaga,
  getFaqCategoryByIdSaga,
  updateFaqCategorySaga,
  deleteFaqCategorySaga,
} from './faqCategorySaga';

import { getFaqSaga, createFaqSaga, getFaqByIdSaga, updateFaqSaga, deleteFaqSaga } from './faqSaga';
import { getContactSaga, getContactByIdSaga } from './contactSaga';

import {
  getHotCareersSaga,
  getHotCareersByIdSaga,
  updateHotCareersSaga,
  sortHotCareersSaga,
} from './hotCareersSaga';

import {
  getHotIndustriesSaga,
  getHotIndustriesByIdSaga,
  createHotIndustrySaga,
  updateHotIndustriesSaga,
  sortHotIndustriesSaga,
  getIndustriesSaga,
  deleteHotIndustrySaga,
} from './hotIndustriesSaga';

import { getGlobalSettingSaga, updateGlobalSettingSaga } from './globalSettingSaga';
import loadDataChart from './dashboardSaga';
import { getQuestionsSaga, saveQuestionsSaga } from './questionSaga';
import { getPromptsSaga, updatePromptSaga } from './promptSaga';

export default function* sagas() {
  yield all(
    [
      authenticationSaga,

      getFunctionByDepartmentIdSaga,
      getFunctionsSaga,
      createFunctionSaga,
      updateFunctionSaga,
      deleteFunctionSaga,

      getDepartmentsSaga,
      updateDepartmentSaga,
      createDepartmentSaga,

      getEmailTemplatesSaga,
      getEmailTemplatesSystemSaga,
      updateEmailTemplateSaga,
      updateEmailTemplateSystemSaga,
      createEmailTemplateSaga,
      createEmailTemplateSystemSaga,
      deleteEmailTemplateSystemSaga,

      getPromptsSaga,
      updatePromptSaga,
      
      getTemplateSaga,
      saveTemplateSaga,
      getFunctionsByIdDeparmentSaga,

      getExpertisesByFunctionIdSaga,
      createExpertiseSaga,
      updateExpertiseSaga,
      deleteExpertiseByIdSaga,

      getDescriptionSaga,
      saveDescriptionSaga,
      deleteDescriptionSaga,
      updateOrderDescriptionSaga,

      getRecruitersSaga,
      createRecruiterSaga,
      updateRecruiterSaga,
      deleteRecruiterSaga,
      getJobsByRecruiterIdSaga,
      getJobseekersSaga,
      updateJobseekerSaga,
      getJobsByJobseekerIdSaga,

      getNewsSaga,
      createNewsSaga,
      updateNewsSaga,
      deleteNewsSaga,
      getNewsByIdSaga,
      getNewsCategoriesSaga,

      getTagsSaga,
      updateTagSaga,
      createTagSaga,
      deleteTagSaga,
      getTagByIdSaga,

      getCategoriesSaga,
      updateCategorySaga,
      createCategorySaga,
      getCategoryByIdSaga,
      sortCategorySaga,
      deleteCategorySaga,
      uploadFileSaga,

      getFaqCategoriesSaga,
      createFaqCategorySaga,
      getFaqCategoryByIdSaga,
      updateFaqCategorySaga,
      deleteFaqCategorySaga,

      getFaqSaga,
      createFaqSaga,
      getFaqByIdSaga,
      updateFaqSaga,
      deleteFaqSaga,

      getContactSaga,
      getContactByIdSaga,
      getHotCareersSaga,
      getHotCareersByIdSaga,
      updateHotCareersSaga,
      sortHotCareersSaga,

      getHotIndustriesSaga,
      getHotIndustriesByIdSaga,
      createHotIndustrySaga,
      updateHotIndustriesSaga,
      sortHotIndustriesSaga,
      getIndustriesSaga,
      deleteHotIndustrySaga,

      getGlobalSettingSaga,
      updateGlobalSettingSaga,

      getQuestionsSaga,
      saveQuestionsSaga,

      loadDataChart,
    ].map(saga => fork(saga)),
  );
}
