export const GET_GLOBAL_SETTING = 'GET_GLOBAL_SETTING';
export const GET_GLOBAL_SETTING_SUCCESS = 'GET_GLOBAL_SETTING_SUCCESS';
export const GET_GLOBAL_SETTING_FAIL = 'GET_GLOBAL_SETTING_FAIL';

export const UPDATE_GLOBAL_SETTING = 'UPDATE_GLOBAL_SETTING';
export const UPDATE_GLOBAL_SETTING_SUCCESS = 'UPDATE_GLOBAL_SETTING_SUCCESS';
export const UPDATE_GLOBAL_SETTING_FAIL = 'UPDATE_GLOBAL_SETTING_FAIL';

export function getGlobalSetting() {
  return {
    type: GET_GLOBAL_SETTING,
  };
}

export function getGlobalSettingSuccess(data) {
  return {
    type: GET_GLOBAL_SETTING_SUCCESS,
    data,
  };
}

export function getGlobalSettingFail(error) {
  return {
    type: GET_GLOBAL_SETTING_FAIL,
    error,
  };
}


export function updateGlobalSetting(id, params) {
  return {
    type: UPDATE_GLOBAL_SETTING,
    id,
    params,
  };
}

export function updateGlobalSettingSuccess(data) {
  return {
    type: UPDATE_GLOBAL_SETTING_SUCCESS,
    data,
  };
}

export function updateGlobalSettingFail(error) {
  return {
    type: UPDATE_GLOBAL_SETTING_FAIL,
    error,
  };
}
