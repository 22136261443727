export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export function uploadFile(file) {
  return {
    type: UPLOAD_FILE,
    file,
  };
}

export function uploadFileSuccess(data) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    data,
  };
}

export function uploadFileFail() {
  return {
    type: UPLOAD_FILE_FAIL,
  };
}
