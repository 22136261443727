import {
  TOOGLE_DEPARTMENT_MODAL_SHOW, TOOGLE_FUNCTION_MODAL_SHOW, SelectedHeaderModalType, TOOGLE_MODAL_NONE,
} from '../actions/modalSelectedHeaderAction';

const initialState = {
  modal: SelectedHeaderModalType.None,
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOOGLE_DEPARTMENT_MODAL_SHOW: {
      return { ...state, modal: SelectedHeaderModalType.DEPARTMENT, data: action.data };
    }
    case TOOGLE_FUNCTION_MODAL_SHOW: {
      return { ...state, modal: SelectedHeaderModalType.FUNCTION, data: action.data };
    }
    case TOOGLE_MODAL_NONE: {
      return { ...state, modal: SelectedHeaderModalType.None, data: [] };
    }
    default:
      return state;
  }
}
