import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import {
  // cryptoTableReducer,
  // newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  modalReducer,
  templateReducer,
  departmentReducer,
  functionReducer,
  modalSelectedHeaderReducer,
  authenticationReducer,
  expertiseReducer,
  descriptionReducer,
  emailTemplateReducer,
  recruiterReducer,
  jobseekerReducer,
  newsReducer,
  tagReducer,
  categoryReducer,
  uploadFileReducer,
  faqCategoryReducer,
  faqReducer,
  contactReducer,
  hotCareersReducer,
  hotIndustriesReducer,
  globalSettingReducer,
  dashboardReducer,
  questionReducers,
  promptReducer,
} from '../../redux/reducers/index';
import sagas from '../../redux/sagas/index';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  // cryptoTable: cryptoTableReducer,
  // newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  authentication: authenticationReducer,
  modal: modalReducer,
  template: templateReducer,
  department: departmentReducer,
  selectedHeaderModal: modalSelectedHeaderReducer,
  func: functionReducer,
  expertise: expertiseReducer,
  description: descriptionReducer,
  emailTemplate: emailTemplateReducer,
  emailTemplateSystem: emailTemplateReducer,
  prompt: promptReducer,
  recruiter: recruiterReducer,
  jobseeker: jobseekerReducer,
  news: newsReducer,
  uploadFile: uploadFileReducer,
  tag: tagReducer,
  category: categoryReducer,
  faqCategory: faqCategoryReducer,
  faq: faqReducer,
  contact: contactReducer,
  hotCareers: hotCareersReducer,
  hotIndustries: hotIndustriesReducer,
  globalSetting: globalSettingReducer,
  dashboard: dashboardReducer,
  questions: questionReducers,
});
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(sagas);

export default store;
