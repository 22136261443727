/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAIL,
  CREATE_FAQ,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAIL,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  GET_FAQ_BY_ID,
  GET_FAQ_BY_ID_SUCCESS,
  GET_FAQ_BY_ID_FAIL,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  RESET_MESSAGE,
} from '../actions/faqAction';

import {
  MSG_UPDATE_FAQ_SUCCESS,
  MSG_UPDATE_FAQ_FAIL,
  MSG_CREATE_FAQ_SUCCESS,
  MSG_CREATE_FAQ_FAIL,
  MSG_DELETE_FAQ_SUCCESS,
  MSG_DELETE_FAQ_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  faqList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  faqEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ: {
      return { ...state, type: action.type };
    }
    case GET_FAQ_SUCCESS: {
      return { ...state, ...{ type: action.type, faqList: action.data } };
    }
    case GET_FAQ_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_FAQ_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_FAQ_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, faqEdit: action.data } };
    }
    case GET_FAQ_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_FAQ: {
      return { ...state, type: action.type };
    }
    case CREATE_FAQ_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_CREATE_FAQ_SUCCESS,
      };
    }
    case CREATE_FAQ_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_CREATE_FAQ_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case UPDATE_FAQ: {
      return { ...state, type: action.type };
    }
    case UPDATE_FAQ_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_UPDATE_FAQ_SUCCESS,
      };
    }
    case UPDATE_FAQ_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_UPDATE_FAQ_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_FAQ: {
      return { ...state, type: action.type };
    }
    case DELETE_FAQ_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_DELETE_FAQ_SUCCESS,
        // eslint-disable-next-line no-underscore-dangle
        faqList: state.faqList.filter(item => item._id !== action.data.id),
      };
    }
    case DELETE_FAQ_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_DELETE_FAQ_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }

    case RESET_MESSAGE: {
      return {
        ...state,
        message: '',
      };
    }

    default:
      return state;
  }
}
