import api from '../index';
import ApiConstants from '../ApiConstants';

export const getCategories = () => api(
  `${ApiConstants.NEWS_CATEGORIES}/get-all`,
  null,
  'get',
  null,
);
export const createCategory = news => api(
  `${ApiConstants.NEWS_CATEGORIES}`,
  news,
  'POST',
  null,
);
export const updateCategory = args => api(
  `${ApiConstants.NEWS_CATEGORIES}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const deleteCategory = id => api(
  `${ApiConstants.NEWS_CATEGORIES}/${id}`,
  null,
  'DELETE',
  null,
);

export const emptyCategory = id => api(
  `${ApiConstants.NEWS_CATEGORIES}/del-for-news/${id}`,
  null,
  'DELETE',
  null,
);

export const getCategoryById = id => api(
  `${ApiConstants.NEWS_CATEGORIES}/${id}`,
  null,
  'GET',
  null,
);
