import api from '../index';
import ApiConstants from '../ApiConstants';

export const getGlobalSetting = () => api(
  `${ApiConstants.GET_GLOBAL_SETTING}`,
  null,
  'get',
  null,
);
export const updateGlobalSetting = args => api(
  `${ApiConstants.PATCH_GLOBAL_SETTING}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);
