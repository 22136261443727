import {
  LOADING_DATA_CHART,
  LOAD_DATA_CHART_SUCCESS,
  LOAD_DATA_CHART_FAILED,
} from '../actions/dashboardAction';

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  statusCode: '',
  data: {},
};


export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_CHART: {
      return { ...state, isLoading: true };
    }
    case LOAD_DATA_CHART_FAILED: {
      return {
        ...state, data: {}, isError: true, statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case LOAD_DATA_CHART_SUCCESS: {
      return { ...state, data: action.data ? action.data : {}, isLoading: false };
    }
    default:
      return state;
  }
}
