import { take, call, put } from 'redux-saga/effects';
import {
  GET_FUNCTIONS,
  GET_FUNCTION_FAILED,
  GET_FUNCTION_SUCCESS,
  CREATE_FUNCTION,
  CREATE_FUNCTION_FAILED,
  CREATE_FUNCTION_SUCCESS,
  UPDATE_FUNCTION,
  UPDATE_FUNCTION_FAILED,
  UPDATE_FUNCTION_SUCCESS,
  DELETE_FUNCTION,
  DELETE_FUNCTION_FAILED,
  DELETE_FUNCTION_SUCCESS,
  GET_FUNCTIONS_BY_ID_DEPARTMENT,
  GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS,
  GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL,
} from '../actions/functionAction';
import {
  getFunctions,
  createFunction,
  updateFunction,
  deleteFunction,
  getFunctionsByDepartmentId,
} from '../../shared/api/methods/function';

export function* getFunctionsSaga() {
  while (true) {
    try {
      yield take(GET_FUNCTIONS);
      const data = yield call(getFunctions);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_FUNCTION_FAILED, error });
      } else {
        yield put({ type: GET_FUNCTION_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_FUNCTION_FAILED, error });
    }
  }
}

export function* getFunctionsByIdDeparmentSaga() {
  while (true) {
    try {
      const { departmentId } = yield take(GET_FUNCTIONS_BY_ID_DEPARTMENT);
      const data = yield call(getFunctionsByDepartmentId, departmentId);
      yield put({ type: GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS, data });
    } catch (error) {
      yield put({ type: GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL, error });
    }
  }
}

export function* createFunctionSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_FUNCTION);
      const data = yield call(createFunction, params);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_FUNCTION_FAILED, error });
      } else {
        yield put({ type: CREATE_FUNCTION_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_FUNCTION_FAILED, error });
    }
  }
}

export function* updateFunctionSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_FUNCTION);
      const data = yield call(updateFunction, [id, params]);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_FUNCTION_FAILED, error });
      } else {
        yield put({ type: UPDATE_FUNCTION_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_FUNCTION_FAILED, error });
    }
  }
}

export function* deleteFunctionSaga() {
  while (true) {
    try {
      const { func } = yield take(DELETE_FUNCTION);
      const data = yield call(deleteFunction, { ...func.func, isDeleted: true });
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_FUNCTION_FAILED, error });
      } else {
        yield put({ type: DELETE_FUNCTION_SUCCESS, data });
        const functions = yield call(getFunctions);
        if (Object.prototype.hasOwnProperty.call(functions, 'error')) {
          const { error } = data;
          yield put({ type: GET_FUNCTION_FAILED, error });
        } else {
          yield put({ type: GET_FUNCTION_SUCCESS, data: functions });
        }
      }
    } catch (error) {
      yield put({ type: DELETE_FUNCTION_FAILED, error });
    }
  }
}
