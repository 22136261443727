/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  CREATE_NEWS,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAIL,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  GET_NEWS_BY_ID,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_SUCCESS,
  GET_NEWS_CATEGORIES_FAIL,
} from '../actions/newsAction';
import {
  getAllNews,
  createNews,
  updateNews,
  deleteNews,
  getNewsById,
  getNewsCategories,
} from '../../shared/api/methods/news';

export function* getNewsSaga() {
  while (true) {
    try {
      const { query, order, asc, limit, skip } = yield take(GET_NEWS);
      const data = yield call(getAllNews, [query, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_NEWS_FAIL, error });
      } else {
        yield put({ type: GET_NEWS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_NEWS_FAIL, error });
    }
  }
}

export function* getNewsCategoriesSaga() {
  while (true) {
    try {
      const { limit, skip } = yield take(GET_NEWS_CATEGORIES);
      const data = yield call(getNewsCategories, [limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_NEWS_CATEGORIES_FAIL, error });
      } else {
        yield put({ type: GET_NEWS_CATEGORIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_NEWS_CATEGORIES_FAIL, error });
    }
  }
}

export function* createNewsSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_NEWS);
      const data = yield call(createNews, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_NEWS_FAIL, error });
      } else {
        yield put({ type: CREATE_NEWS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_NEWS_FAIL, error });
    }
  }
}

export function* updateNewsSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_NEWS);
      const data = yield call(updateNews, [id, params]);
      const { isDeleted } = params;
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_NEWS_FAIL, error, isDeleted });
      } else {
        yield put({ type: UPDATE_NEWS_SUCCESS, data, isDeleted });
      }
    } catch (error) {
      yield put({ type: UPDATE_NEWS_FAIL, error });
    }
  }
}

export function* deleteNewsSaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_NEWS);
      const data = yield call(deleteNews, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_NEWS_FAIL, error });
      } else {
        yield put({ type: DELETE_NEWS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_NEWS_FAIL, error });
    }
  }
}

export function* getNewsByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_NEWS_BY_ID);
      const data = yield call(getNewsById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_NEWS_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_NEWS_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_NEWS_BY_ID_FAIL, error });
    }
  }
}
