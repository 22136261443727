import api from '../index';
import ApiConstants from '../ApiConstants';

export const getCategories = args => api(
  // eslint-disable-next-line max-len
  `${ApiConstants.FAQ_CATEGORIES}?userRole=${args[0]}&order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
);

export const createCategory = data => api(
  `${ApiConstants.FAQ_CATEGORIES}`,
  data,
  'POST',
);

export const updateCategory = args => api(
  `${ApiConstants.FAQ_CATEGORIES}/${args[0]}`,
  args[1],
  'PATCH',
);

export const deleteCategory = id => api(
  `${ApiConstants.FAQ_CATEGORIES}/${id}`,
  null,
  'DELETE',
);

export const getCategoryById = id => api(
  `${ApiConstants.FAQ_CATEGORIES}/${id}`,
  null,
  'GET',
);
