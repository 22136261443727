/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAIL,
  CREATE_FAQ,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAIL,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  GET_FAQ_BY_ID,
  GET_FAQ_BY_ID_SUCCESS,
  GET_FAQ_BY_ID_FAIL,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
} from '../actions/faqAction';
import {
  getFaq,
  createFaq,
  updateFaq,
  getFaqById,
  deleteFaq,
} from '../../shared/api/methods/faq';

export function* getFaqSaga() {
  while (true) {
    try {
      const { query, catId, faqType, order, asc, limit, skip } = yield take(GET_FAQ);
      const data = yield call(getFaq, [query, catId, faqType, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_FAQ_FAIL, error });
      } else {
        yield put({ type: GET_FAQ_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_FAQ_FAIL, error });
    }
  }
}

export function* createFaqSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_FAQ);
      const data = yield call(createFaq, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_FAQ_FAIL, error });
      } else {
        yield put({ type: CREATE_FAQ_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_FAQ_FAIL, error });
    }
  }
}

export function* updateFaqSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_FAQ);
      const data = yield call(updateFaq, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_FAQ_FAIL, error });
      } else {
        yield put({ type: UPDATE_FAQ_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_FAQ_FAIL, error });
    }
  }
}

export function* getFaqByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_FAQ_BY_ID);
      const data = yield call(getFaqById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_FAQ_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_FAQ_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_FAQ_BY_ID_FAIL, error });
    }
  }
}

export function* deleteFaqSaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_FAQ);
      const data = yield call(deleteFaq, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_FAQ_FAIL, error });
      } else {
        yield put({ type: DELETE_FAQ_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_FAQ_FAIL, error });
    }
  }
}
