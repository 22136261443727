import {
  LOGGINING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from '../actions/authenticationAction';

const initialState = {
  isLoging: false,
  message: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGGINING: {
      return { ...state, isLoging: true };
    }
    case LOGIN_SUCCESS: {
      return { ...state, message: action.data ? action.data.message : '', isLoging: false };
    }
    case LOGIN_FAILED: {
      return { ...state, message: '', isLoging: false };
    }
    default:
      return state;
  }
}
