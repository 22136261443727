export const LOAD_DESCRIPTION = 'LOAD_DESCRIPTION';
export const LOADING_DESCRIPTION = 'LOADING_DESCRIPTION';
export const LOAD_DESCRIPTION_SUCCESS = 'LOAD_DESCRIPTION_SUCCESS';
export const LOAD_DESCRIPTION_FAILED = 'LOAD_DESCRIPTION_FAILED';
export const SAVE_DESCRIPTION = 'SAVE_DESCRIPTION';
export const SAVING_DESCRIPTION = 'SAVING_DESCRIPTION';
export const SAVE_DESCRIPTION_SUCCESS = 'SAVE_DESCRIPTION_SUCCESS';
export const SAVE_DESCRIPTION_FAILED = 'SAVE_DESCRIPTION_FAILED';

export const ADD_FIELD_DESCRIPTION = 'ADD_FIELD_DESCRIPTION';
export const UPDATE_FIELD_DESCRIPTION = 'UPDATE_FIELD_DESCRIPTION';
export const DELETE_FIELD_DESCRIPTION = 'DELETE_FIELD_DESCRIPTION';
export const DELETE_FIELD_DESCRIPTION_SUCCESS = 'DELETE_FIELD_DESCRIPTION_SUCCESS';
export const DELETE_FIELD_DESCRIPTION_FAILED = 'DELETE_FIELD_DESCRIPTION_FAILED';

export const UPDATE_ORDER_DESCRIPTION = 'UPDATE_ORDER_DESCRIPTION';
export const UPDATE_ORDER_DESCRIPTION_SUCCESS = 'UPDATE_ORDER_DESCRIPTION_SUCCESS';
export const UPDATE_ORDER_DESCRIPTION_FAILED = 'UPDATE_ORDER_DESCRIPTION_FAILED';

// export function loadMaterTemplate() {
//   return {
//     type: LOAD_MASTER_DESCRIPTION,
//   };
// }

export function loadDescription() {
  return {
    type: LOAD_DESCRIPTION,
  };
}

export function saveDescription(dataSave) {
  return {
    type: SAVE_DESCRIPTION,
    dataSave,
  };
}

export function addNewField(data, modal) {
  return {
    type: ADD_FIELD_DESCRIPTION,
    data,
    modal,
  };
}

export function updateField(data, modal) {
  return {
    type: UPDATE_FIELD_DESCRIPTION,
    data,
    modal,
  };
}

export function deleteField(idRow) {
  return {
    type: DELETE_FIELD_DESCRIPTION,
    idRow,
  };
}
export function updateOrderDescription(data) {
  return {
    type: UPDATE_ORDER_DESCRIPTION,
    data,
  };
}
