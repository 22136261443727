/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import {
  GET_HOT_CAREERS,
  GET_HOT_CAREERS_SUCCESS,
  GET_HOT_CAREERS_FAIL,
  GET_HOT_CAREERS_BY_ID,
  GET_HOT_CAREERS_BY_ID_SUCCESS,
  GET_HOT_CAREERS_BY_ID_FAIL,
  UPDATE_HOT_CAREERS,
  UPDATE_HOT_CAREERS_SUCCESS,
  UPDATE_HOT_CAREERS_FAIL,
  SORT_HOT_CAREERS,
  SORT_HOT_CAREERS_SUCCESS,
  SORT_HOT_CAREERS_FAIL,
} from '../actions/hotCareersAction';
import {
  updateHotCareersIndustries,
} from '../../shared/utils/calculation';

const initialState = {
  hotCareersList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  hotCareersEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOT_CAREERS: {
      return { ...state, type: action.type };
    }
    case GET_HOT_CAREERS_SUCCESS: {
      return { ...state, ...{ type: action.type, hotCareersList: action.data } };
    }
    case GET_HOT_CAREERS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_HOT_CAREERS_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_HOT_CAREERS_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, hotCareersEdit: action.data } };
    }
    case GET_HOT_CAREERS_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case UPDATE_HOT_CAREERS: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_HOT_CAREERS_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          hotCareersEdit: action.data,
          hotCareersList: updateHotCareersIndustries(state.hotCareersList, action.data),
        },
      };
    }
    case UPDATE_HOT_CAREERS_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }

    case SORT_HOT_CAREERS: {
      return { ...state, type: action.type };
    }
    case SORT_HOT_CAREERS_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          hotCareersList: action.data,
        },
      };
    }
    case SORT_HOT_CAREERS_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
