export const GET_FUNCTIONS = 'GET_FUNCTIONS';
export const TOGGLE_ADD_FUNCTION = 'TOGGLE_ADD_FUNCTION';
export const TOGGLE_UPDATE_FUNCTION = 'TOGGLE_UPDATE_FUNCTION';
export const TOGGLE_DELETE_FUNCTION = 'TOGGLE_DELETE_FUNCTION';
export const HIDE_STATUS_REQUEST = 'HIDE_STATUS_REQUEST';
export const GET_FUNCTION_SUCCESS = 'GET_FUCNTION_SUCCESS';
export const GET_FUNCTION_FAILED = 'GET_FUCNTION_FAILED';
export const GET_FUNCTION_LOADING = 'GET_FUCNTION_LOADING';
export const CREATE_FUNCTION = 'CREATE_FUNCTION';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUCNTION_SUCCESS';
export const CREATE_FUNCTION_FAILED = 'CREATE_FUCNTION_FAILED';
export const CREATE_FUNCTION_LOADING = 'CREATE_FUCNTION_LOADING';
export const CANCEL_FUNCTION_ACTION = 'CANCEL_FUCTION_ACTION';
export const UPDATE_FUNCTION = 'UPDATE_FUNCTION';
export const UPDATE_FUNCTION_SUCCESS = 'UPDATE_FUNCTION_SUCCESS';
export const UPDATE_FUNCTION_FAILED = 'UPDATE_FUNCTION_FAILED';
export const UPDATE_FUNCTION_LOADING = 'UPDATE_FUNCTION_LOADING';
export const DELETE_FUNCTION = 'DELETE_FUNCTION';
export const DELETE_FUNCTION_SUCCESS = 'DELETE_FUNCTION_SUCCESS';
export const DELETE_FUNCTION_FAILED = 'DELETE_FUNCTION_FAILED';
export const DELETE_FUNCTION_LOADING = 'DELETE_FUNCTION_LOADING';
export const GET_FUNCTIONS_BY_ID_DEPARTMENT = 'GET_FUNCTIONS_BY_ID_DEPARTMENT';
export const GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS = 'GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS';
export const GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL = 'GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL';

export const ModalShowType = {
  NONE: 'None',
  DELETE_CONFIRM: 'DeleteConfirm',
  EDIT_MODAL: 'EditModal',
  ADD_MODAL: 'AddModal',
};

export function getFunctions() {
  return {
    type: GET_FUNCTIONS,
  };
}

export function cancel() {
  return {
    type: CANCEL_FUNCTION_ACTION,
  };
}

export function toggleAddFunction(department) {
  return {
    type: TOGGLE_ADD_FUNCTION,
    department,
  };
}

export function toggleUpdateFunction(department) {
  return {
    type: TOGGLE_UPDATE_FUNCTION,
    department,
  };
}

export function toggleDeleteFunction(department) {
  return {
    type: TOGGLE_DELETE_FUNCTION,
    department,
  };
}

export function getFunctionsByDepartmentId(departmentId) {
  return {
    type: GET_FUNCTIONS_BY_ID_DEPARTMENT,
    departmentId,
  };
}

export function getFunctionsByDepartmentIdSuccess(data) {
  return {
    type: GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS,
    data,
  };
}

export function getFunctionsByDepartmentIdFail() {
  return {
    type: GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL,
  };
}

export function createFunction(params) {
  return {
    type: CREATE_FUNCTION,
    params,
  };
}

export function createFunctionSuccess(data) {
  return {
    type: CREATE_FUNCTION_SUCCESS,
    data,
  };
}

export function createFunctionFail() {
  return {
    type: CREATE_FUNCTION_FAILED,
  };
}

export function updateFunction(id, params) {
  return {
    type: UPDATE_FUNCTION,
    id,
    params,
  };
}

export function updateFunctionSuccess(data) {
  return {
    type: UPDATE_FUNCTION_SUCCESS,
    data,
  };
}

export function updateFunctionFail() {
  return {
    type: UPDATE_FUNCTION_FAILED,
  };
}

export function deleteFunction(func) {
  return {
    type: DELETE_FUNCTION,
    func,
  };
}

export function hideStatusRequest() {
  return {
    type: HIDE_STATUS_REQUEST,
  };
}
