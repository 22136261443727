/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_FAQ_CATEGORIES,
  GET_FAQ_CATEGORIES_SUCCESS,
  GET_FAQ_CATEGORIES_FAIL,
  CREATE_FAQ_CATEGORY,
  CREATE_FAQ_CATEGORY_SUCCESS,
  CREATE_FAQ_CATEGORY_FAIL,
  UPDATE_FAQ_CATEGORY,
  UPDATE_FAQ_CATEGORY_SUCCESS,
  UPDATE_FAQ_CATEGORY_FAIL,
  DELETE_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY_SUCCESS,
  DELETE_FAQ_CATEGORY_FAIL,
  GET_FAQ_CATEGORY_BY_ID,
  GET_FAQ_CATEGORY_BY_ID_SUCCESS,
  GET_FAQ_CATEGORY_BY_ID_FAIL,
} from '../actions/faqCategoryAction';
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getCategoryById,
} from '../../shared/api/methods/faqCategory';

export function* getFaqCategoriesSaga() {
  while (true) {
    try {
      const { catType, orderBy, asc, limit, skip } = yield take(GET_FAQ_CATEGORIES);
      const data = yield call(getCategories, [catType, orderBy, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_FAQ_CATEGORIES_FAIL, error });
      } else {
        yield put({ type: GET_FAQ_CATEGORIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_FAQ_CATEGORIES_FAIL, error });
    }
  }
}

export function* createFaqCategorySaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_FAQ_CATEGORY);
      const data = yield call(createCategory, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_FAQ_CATEGORY_FAIL, error });
      } else {
        yield put({ type: CREATE_FAQ_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_FAQ_CATEGORY_FAIL, error });
    }
  }
}

export function* updateFaqCategorySaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_FAQ_CATEGORY);
      const data = yield call(updateCategory, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_FAQ_CATEGORY_FAIL, error });
      } else {
        yield put({ type: UPDATE_FAQ_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_FAQ_CATEGORY_FAIL, error });
    }
  }
}

export function* deleteFaqCategorySaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_FAQ_CATEGORY);
      const data = yield call(deleteCategory, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_FAQ_CATEGORY_FAIL, error });
      } else {
        yield put({ type: DELETE_FAQ_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_FAQ_CATEGORY_FAIL, error });
    }
  }
}

export function* getFaqCategoryByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_FAQ_CATEGORY_BY_ID);
      const data = yield call(getCategoryById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_FAQ_CATEGORY_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_FAQ_CATEGORY_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_FAQ_CATEGORY_BY_ID_FAIL, error });
    }
  }
}
