/* eslint-disable max-len */
import api from '../index';
import ApiConstants from '../ApiConstants';

export const getContact = () => api(
  `${ApiConstants.CONTACT}?order=contactId&asc=-1&limit=100000&skip=0`,
  null,
  'GET',
);

export const getContactById = id => api(
  `${ApiConstants.CONTACT}/${id}`,
  null,
  'GET',
);
