export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAIL = 'GET_CONTACT_FAIL';

export const GET_CONTACT_BY_ID = 'GET_CONTACT_BY_ID';
export const GET_CONTACT_BY_ID_SUCCESS = 'GET_CONTACT_BY_ID_SUCCESS';
export const GET_CONTACT_BY_ID_FAIL = 'GET_CONTACT_BY_ID_FAIL';

export const RESET_MESSAGE = 'RESET_MESSAGE';

export function getContact() {
  return {
    type: GET_CONTACT,
  };
}

export function getContactSuccess(data) {
  return {
    type: GET_CONTACT_SUCCESS,
    data,
  };
}

export function getContactFail(error) {
  return {
    type: GET_CONTACT_FAIL,
    error,
  };
}

export function getContactById(id) {
  return {
    type: GET_CONTACT_BY_ID,
    id,
  };
}

export function getContactByIdSuccess(data) {
  return {
    type: GET_CONTACT_BY_ID_SUCCESS,
    data,
  };
}

export function getContactByIdFail(error) {
  return {
    type: GET_CONTACT_BY_ID_FAIL,
    error,
  };
}
