import api from '../index';
import ApiConstants from '../ApiConstants';

export const getHotIndustries = () => api(
  `${ApiConstants.HOT_INDUSTRIES}/admin?order=sortNum&asc=1&limit=100000&skip0`,
  null,
  'get',
  null,
);
export const createHotIndustry = args => api(
  `${ApiConstants.HOT_INDUSTRIES}`,
  args,
  'POST',
  null,
);
export const updateHotIndustries = args => api(
  `${ApiConstants.HOT_INDUSTRIES}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);
export const getHotIndustriesById = id => api(
  `${ApiConstants.HOT_INDUSTRIES}/${id}`,
  null,
  'GET',
  null,
);
export const deleteHotIndustry = id => api(
  `${ApiConstants.HOT_INDUSTRIES}/${id}`,
  null,
  'DELETE',
  null,
);
export const getIndustriesFromMasterData = () => api(
  `${ApiConstants.HOT_INDUSTRIES}/get-data-master`,
  null,
  'GET',
  null,
);
